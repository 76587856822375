@media(max-width: 369.98px){ 
    .navbarMenu-content {
        padding: 0% 5%;
    }
}
  
@media (min-width: 369.99px) and (max-width: 767.98px) {
    .navbarMenu-content {
        max-width: 540px;
        padding: 0% 5%;
    }
}
    
@media (min-width: 767.99px) and (max-width: 991.98px) {
    .navbarMenu-content {
      max-width: 720px;
      padding: 0% 5%;
    }
}
    
@media (min-width: 991.99px) and (max-width: 1199.98px) {
    .navbarMenu-content {
      max-width: 960px;
    }
}
    
@media (min-width: 1199.99px) {
    .navbarMenu-content {
      max-width: 1140px;
    }
}


@media (max-width: 991.98px) {
    .navbarMenu{
        content: ' ';
        top: 75.98px;
        left: 0;
        width: 100%;
        position: absolute;
        z-index: -1;
        background-color: rgba(255, 255, 255, 0.85);
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        -moz-backdrop-filter: blur(4px);
        -ms-backdrop-filter: blur(4px);
        flex: 100%;
    }
    .navbarMenu-content{
        flex-direction: column;
        overflow: hidden;
        margin: 40px auto;
    }    

    .navbarMenu-content ul{
        flex-direction: column;
        align-items: left;
        margin: -10px -0;
    }

    .navbarMenu-content ul > *{
        margin: 10px 0;
    }
}
    
@media (min-width: 991.99px){
    .navbarMenu-content, .navbarMenu{
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        align-items: center;
    }

    .navbarMenu-content ul{
        flex-direction: row;
        align-items: center;
        margin: 0 -10px;
    }
    
    .navbarMenu-content ul > *{
        margin: 0 10px;
    }
}

.navbarMenu{
    text-transform: uppercase;
}

.not-visible{
    max-height: 0px;
    transition: max-height 0.3s ease;
}

.visible{
    max-height: 400px; 
    transition: max-height 0.3s ease;
}

.navbarMenu-content{
    display: flex;
    display: -webkit-flex;
}

.navbarMenu-content li{
    height: 100%;
    font-size: 13px;
    color: var(--black);
    font-weight: 700;
    letter-spacing: 0.15vw;
    transition: 0.3s ease;
    cursor: pointer;
}
  
.navbarMenu-content li:hover{
    color: var(--blue);
    transition: 0.3s ease;
}

.navbarMenu-content ul{
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
    height: 100%;
}

.navbarMenu .scroll{
    box-shadow:  0.1rem 0.08rem rgb(0 0 0 / 8%)
}