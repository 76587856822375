.form-container .form{
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    margin: -10px;
}
.form-container .form > *{
    margin: 10px;
}

.form-container .form-wrap > *{
    margin: 10px;;
}
.form-container .form-wrap{
    display: flex;
    margin: -10px;
    min-width: 100%;
    padding: 10px 0px;
}

.form-container .form input[type=text], .form-container .form input[type=email], .form-container .form textarea{
    width: calc(100% - 20px)
}

.form-container .input{
    flex: 1;
}

.form-container .form-wrap input[type=text]{
    width: calc(100% - 20px)
}

.form-container input[type=submit]{
    padding: 0;
    outline: none;
    border: none;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
}

.form-container input[type=text], .form-container input[type=email], .form-container input[type=number], .form-container textarea{
    padding: 10px;
    outline: none;
    border: 2px solid var(--lightgrey);
    caret-color: var(--blue);
    width: calc(100% - 20px)
}

.form-container input[type=text]:valid:focus, .form-container input[type=email]:focus, .form-container input[type=number]:focus, .form-container textarea:focus{
    border: 2px solid var(--blue);
}

.form-container textarea{
    resize: vertical;
}

.form-container input[type=text]:valid, .form-container input[type=email]:valid, .form-container input[type=number]:valid, .form-container textarea:valid{
    border: 2px solid var(--lightgrey);
}

.form-container input[type=text]:valid[focused=true], .form-container input[type=email]:valid[focused=true], .form-container input[type=number]:valid[focused=true], .form-container textarea:valid[focused=true]{
    border: 2px solid var(--blue);
}

.form-container input[type=text]:invalid[focused=true], .form-container input[type=email]:invalid[focused=true], .form-container input[type=number]:invalid[focused=true], .form-container textarea:invalid[focused=true]{
    border: 2px solid var(--red);
}

.form-container .error{
    padding: 10px 0px;
    color: var(--red);
    display: none;
}

.form-container input:invalid[focused=true] ~ .error{
    display: block;
}

.form-container textarea:invalid[focused=true] ~ .error{
    display: block;
}

.form-container input::-webkit-outer-spin-button,
.form-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-container input[type=number] {
  -moz-appearance: textfield;
}
