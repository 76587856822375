.ourOfferItem{
    border: 2px solid var(--red);
    width: 250px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.ourOfferItem:before{
    content: '';
    position: absolute;
    top: 16px;
    left: -4px;
    width: calc(100% - 4px);
    height: calc(100% - 40px);
    background-color: transparent;
    border-left: 6px solid var(--whitesmoke);
    border-right: 6px solid var(--whitesmoke);
    transition: 0.3s ease-in-out;
    transform: scaleY(1);
    transition-delay: 0.3s;
}

.ourOfferItem:hover:before{
    transform: scaleY(0);
}

.ourOfferItem:after{
    content: '';
    position: absolute;
    left: 16px;
    top: -4px;
    height: calc(100% - 4px);
    width: calc(100% - 40px);
    background-color: transparent;
    border-top: 6px solid var(--whitesmoke);
    border-bottom: 6px solid var(--whitesmoke);
    transition: 0.3s ease-in-out;
    transform: scaleX(1);
}

.ourOfferItem:hover:after{
    transform: scaleX(0);
}

.ourOfferItem:hover{
    -webkit-box-shadow: 2px 2px 31px -13px rgba(66, 68, 90, 1);
    -moz-box-shadow: 2px 2px 31px -13px rgba(66, 68, 90, 1);
    box-shadow: 2px 2px 31px -13px rgba(66, 68, 90, 1);
}

.ourOfferItem-content{
    text-align: center;
    color: var(--red);
    padding: 0px 20px;
}

.ourOfferItem-content .icon{
    font-size: 40px;
}

.ourOfferItem .read-more{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    position: absolute;
    bottom: 10px;
    right: 25px;
    font-size: 15px;
    color: var(--black);
    margin: -10px;
    font-weight: 600;
    letter-spacing: 3px;
}

.ourOfferItem .read-more > *{
    margin: 10px;
}