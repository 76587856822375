.contact-item-container{
    position: relative;
}

.contact-item-content{
    display: flex;
    flex-direction: column;
    color: var(--red);
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 40px;
}

.contact-item-content .icon{
    font-size: 30px;
    padding: 10px;
}

.contact-item-content .text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: -1px 0;
}

.contact-item-content .text > *{
   margin: 1px 0;
}

.contact-item-content .text p{
    font-size: 15px;
    letter-spacing: 0px;
}

.contact-item-content .arrow, .contact-item-content .arrow-hover{
    font-size: 20px;
    margin-top: 10px;
}
