@media (max-width: 599.98px) {
    .brand{
        width: 210px;
    }
}

@media (min-width: 599.99px) and (max-width: 767.98px) {
    .brand{
        width: 200px;
    }
}
    
@media (min-width: 767.99px){
    .brand{
        width: 150px;
    }
}

.brand{
    padding: 20px;
    transition: 0.3s ease;
    margin: auto;
}

.brand img{
    vertical-align: middle;
    width: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 
}

.brand:hover{
    transform: scale(110%);
    transition: 0.3s ease;
}