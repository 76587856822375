@media (max-width: 767.98px) {
    .brandsCarousel .swiper{
        max-width: 540px;
    }
    .brandsCarousel .swiper-wrapper{
        max-width: 540px;
    }
}

@media (min-width: 767.99px) and (max-width: 991.98px) {
    .brandsCarousel .swiper{
        width: 720px;
    }
    .brandsCarousel .swiper-wrapper{
        max-width: 720px;
    }
}
    
@media (min-width: 991.99px) and (max-width: 1199.98px) {
    .brandsCarousel .swiper{
        width: 960px;
    }
    .brandsCarousel .swiper-wrapper{
        max-width: 960px;
    }
}
    
@media (min-width: 1199.99px) {
    .brandsCarousel.swiper{
        width: 1140px;
    }
    .brandsCarousel .swiper-wrapper{
        max-width: 1140px;
    }
}

.brandsCarousel .swiper-slide{
    width: 100%;
    height: fit-content !important;
    text-align: center;
    margin: auto;
}

.brandsCarousel {
    position: relative;
    padding-bottom: 15vh;
    padding-top: 5vh;
}

.brandsWrapper{
    position: absolute;
    border-bottom: 1px solid var(--lightgrey);
    border-top: 1px solid var(--lightgrey);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}