.back-to-top-btn{
    position: fixed;
    bottom: 30px;
    right: 50px;
    outline: none;
    background-color: var(--red);
    border: none;
    color: white;
    font-size: 15px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    text-align: center;
    align-items: center;
    z-index: 10;
  }

  .back-to-top-btn[topofpage=true]{
    opacity: 0;
  }
  
  .back-to-top-btn:after{
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
    position: absolute;
    z-index: 10;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
  }