@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@media(max-width: 349.98px){ 
  h1{
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.5vw;
  }
}

@media (min-width: 349.99px) and (max-width: 429.98px){ 
  h1{
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.5vw;
  }
}

@media (min-width: 429.99px) {
  h1{
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0.5vw;
  }
}

:root {
  --blue: #083d77;
  --darkerblue: #062d56;
  --lightblue: #009FB7;
  --red: #a30000;
  --white: #ffffff;
  --whitesmoke: #f5f5f5;
  --black: #110B11;
  --darkgrey: #59595F;
  --lightgrey: #B3B3BC;
}

html, body{
  margin: 0px;
  max-width: 100%;
  overflow-x: hidden;
  -webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}

::-moz-selection { /* Code for Firefox */
  color: var(--white);
  background: var(--blue);
}

::selection {
  color: var(--white);
  background: var(--blue);
}

body {
  font-family: 'Red Hat Display', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p{
  font-size: 16px;
  letter-spacing: 0.2vw;
}

h2{
  letter-spacing: 0.2vw;
  font-weight: 500;
  font-size: 20px;
}

input, textarea, select {
   font-family: inherit; 
   letter-spacing: 3px;
}

img{
  user-select: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none;
  -moz-user-select: none; 
  -ms-user-select: none; 
}

.link{
  text-decoration: none;
}