@media (max-width: 767.98px) {
    .contact-content {
      max-width: 540px;
        padding: 0% 5%;
    }
  }
    
@media (min-width: 767.99px) and (max-width: 991.98px) {
    .contact-content {
      max-width: 720px;
      padding: 0% 5%;
    }
}
    
@media (min-width: 991.99px) and (max-width: 1199.98px) {
    .contact-content {
      min-width: 960px;
    }
}
    
@media (min-width: 1199.99px) {
    .contact-content {
      min-width: 1140px;
    }
}

.contact{
    background-color: var(--whitesmoke);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15vh 0px 25vh 0px;
}

.contact-content header{
  font-size: 50px;
  padding-bottom: 30px;
  letter-spacing: 0.2vw;
  text-transform: uppercase;
  color: var(--black);
}

