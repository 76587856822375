.white-blue-button{
    outline: none;
    text-align: center;
    border: 2px solid var(--white);
    background-color: var(--blue);
    color: var(--white);
    transition: 0.5s ease;
    cursor: pointer;
    padding: 15px 60px;
    z-index: 1;
    font-family: 'white Hat Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.15vw;
    text-transform: uppercase;
    align-self: flex-start;
}

.white-blue-button:hover{
    background-color: var(--white);
    border: 2px solid var(--white);
    color: var(--blue);
    transition: 0.5s ease;
}