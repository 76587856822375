@media (max-width: 767.98px) {
    .ourQualitiesGridItem .icon {
        font-size: 50px;
    }

    .ourQualitiesGridItem{
        width: 180px;
        font-size: 13px;
    }
  }
    
@media (min-width: 767.99px) and (max-width: 991.98px) {
    .ourQualitiesGridItem .icon {
        font-size: 30px;
    }
    .ourQualitiesGridItem .text{
        font-size: 10px;
        width: 140px;
    }
}
    
@media (min-width: 991.99px) and (max-width: 1199.98px) {
    .ourQualitiesGridItem .icon {
        font-size: 40px;
    }
    .ourQualitiesGridItem .text{
        width: 180px;
        font-size: 13px;
    }
}
    
@media (min-width: 1199.99px) {
    .ourQualitiesGridItem .icon {
        font-size: 50px;
    }
    .ourQualitiesGridItem .text{
        width: 180px;
        font-size: 13px;
    }
}

.ourQualitiesGridItem{
    display: flex;
    flex-direction: column;
    color: var(--red);
    padding: 10px 0;
    margin-left: auto;
    margin-right: auto;
}

.ourQualitiesGridItem .icon{
    text-align: center;
}

.ourQualitiesGridItem .text{
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
}