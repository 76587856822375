.footer{
    background-color: var(--darkgrey);
    display: flex;
    justify-content: center;
    padding: 75px 0px;
    margin-top:auto;
}

@media (max-width: 767.98px) {
    .footerGrid {
        max-width: 720px;
        flex-wrap: wrap;
        margin: -30px;
    }
    .footerGrid > *{
        margin: 30px;
    }
}
    
@media (min-width: 767.99px) and (max-width: 991.98px) {
    .footerGrid {
      max-width: 720px;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: -20px;
    }
    .footerGrid > *{
        margin: 20px;
    }
}
    
@media (min-width: 991.99px) and (max-width: 1199.98px) {
    .footerGrid {
      min-width: 960px;
      justify-content: space-between;
      margin: 0 -20px;
    }
    .footerGrid > *{
        margin: 0 20px;
    }
}
    
@media (min-width: 1199.99px) {
    .footerGrid {
      min-width: 1140px;
      justify-content: space-between;
    }
}

.footerGrid{
    display: flex;
}