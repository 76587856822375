@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@media (max-width: 767.98px) {
    .form-container .form-wrap{
        flex-direction: column;
    }
}

.contact-container{
    display: flex;
    flex-direction: column;
    margin: -25px 0;
}

.contact-container > *{
    margin: 25px 0;
}

.form-container{
    flex: 1;
    background-color: var(--white);
    padding: 30px 30px 20px 30px;
    border: 1px solid var(--blue);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.form-container form{
    padding: 30px 0px 0px 0px;
}

.contact-boxes{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    flex-wrap: wrap;
}

.contact-boxes > *{
    margin: 20px 0;
}