.contact-item-wrapper{
    position: relative;
}

.contact-item{
    width: 260px;
    height: 240px;
    position: relative;
}

.contact-item-stick{
    width: 20px;
    height: 240px;
    background-color: var(--blue);
    position: absolute;
    bottom: 0px;
    left: 120px;
}

.contact-item-stick::after{
    content: '';
    position: absolute;
    right: 19.98px;
    width: 0; 
    height: 0; 
    border-top: 120px solid transparent;
    border-bottom: 120px solid transparent; 
    border-right: 120px solid var(--blue); 
}

.contact-item-stick::before{
    content: '';
    position: absolute;
    left: 20px;
    width: 0; 
    height: 0; 
    border-top: 120px solid transparent;
    border-bottom: 120px solid transparent; 
    border-left: 120px solid var(--blue); 
}

.contact-item-inner, .contact-item-inner-hasHover{
    transform: rotate(45deg);
    width: 160px;
    height: 160px;
    position: absolute;
    top: 40px;
    left: -60px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-item-inner::after, .contact-item-inner-hasHover::after, .contact-item-inner::before, .contact-item-inner-hasHover::before{
    content: '';
    background-color: var(--white);
    width: 280px;
    transform: rotate(-45deg);
    position: absolute;
    transition: linear 0.3s;
}

.contact-item-inner::after, .contact-item-inner-hasHover::after{
    left: -70px;
    bottom: 70px;
}

.contact-item-inner::before, .contact-item-inner-hasHover::before{
    left: -69px;
    top: 87px;
}

.contact-item-inner::after, .contact-item-inner::before{
    height: 30px;
}

.contact-item-inner-hasHover::after, .contact-item-inner-hasHover::before {
    height: 0px;
}

.c-white .contact-item-inner, .c-white .contact-item-inner::before, .c-white .contact-item-inner::after,
.c-white .contact-item-inner-hasHover, .c-white .contact-item-inner-hasHover::before, .c-white .contact-item-inner-hasHover::after{
    background-color: var(--white);
}

.c-whitesmoke .contact-item-inner, .c-whitesmoke .contact-item-inner::before, .c-whitesmoke .contact-item-inner::after,
.c-whitesmoke .contact-item-inner-hasHover, .c-whitesmoke .contact-item-inner-hasHover::before, .c-whitesmoke .contact-item-inner-hasHover::after{
    background-color: var(--whitesmoke);
}