.jobOffer-boxes{
    display: flex;
}

@media (max-width: 599.98px) {
    .jobOffer-boxes{
        margin: 0 -5px;
        flex-direction: column;
    }

    .jobOffer-boxes > *{
        margin: 0 5px;
    }
}

@media (min-width: 599.99px) and (max-width: 991.98px) {
    .jobOffer-boxes{
        margin: 0 -2.5vw;
    }

    .jobOffer-boxes > *{
        margin: 0 2.5vw;
    }
}

@media (min-width: 991.98px) {
    .jobOffer-boxes{
        margin: 0 -100px;
    }

    .jobOffer-boxes > *{
        margin: 0 100px;
    }
}