/* @media (max-width: 599.98px) {
  .jobOfferHome::before {
    background: url("../../../../../public/images/home/jobOfferHome/jobOfferHome.jpg"); 
    background-size: cover;
    background-position: right -70px top 15%;
  }
} */

/* @media (min-width: 599.99px) { */
  .jobOfferHome::before {
    background: url("../../../../../public/images/home/jobOfferHome/jobOfferHome-blur.jpg"); 
    background-size: cover;
    background-position: center top 30%;
  }
/* } */

@media (max-width: 767.98px) {
    .jobOfferHome-content {
        max-width: 540px;
        padding-left: 5%;
        padding-right: 5%;
    }
  }
    
@media (min-width: 767.99px) and (max-width: 991.98px) {
    .jobOfferHome-content {
      max-width: 720px;
      padding-left: 5%;
      padding-right: 5%;
    }
}
    
@media (min-width: 991.99px) and (max-width: 1199.98px) {
    .jobOfferHome-content {
      max-width: 960px;
    }
}
    
@media (min-width: 1199.99px) {
    .jobOfferHome-content {
      max-width: 1140px;
    }
}

.jobOfferHome{
    position: relative;
    background-color: var(--black);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.jobOfferHome::before{ 
    content: "";
    opacity: 0.3;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;  
}

.jobOfferHome-content {
  padding-top: 30px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -75px 0;
  color: var(--white);
  opacity: 85%;
}

.jobOfferHome-content > *{
  margin: 75px 0;
}