.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
}

.cookie-button-decline{
    background-color: var(--darkgrey) !important;
    letter-spacing: 0.2vw !important;
    color: var(--white) !important;
    border: 2px solid var(--white) !important;
    padding: 10px 20px !important;
    opacity: 80% !important;
    transition: 0.3s ease;
}

.cookie-button-decline:hover{
    background-color: var(--darkerblue) !important;
}

.cookie-button-accept{
    background-color: var(--blue) !important;
    letter-spacing: 0.2vw !important;
    color: var(--white) !important;
    border: 2px solid var(--white) !important;
    padding: 10px 20px !important;
    opacity: 80% !important;
    transition: 0.3s ease;
}

.cookie-button-accept:hover{
    background-color: var(--darkerblue) !important;
}