.dropdown-content a {
    font-size: 12px;
}

@media (max-width: 991.98px) {
    .dropbtn .icon{
        display: none;
    }

    .dropdown-content {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
    }

    .dropdown-content a {
        color: var(--black);
        text-decoration: none;
        display: block;
        transition: 0.3s ease;
        position: relative;
        padding: 0px  10px;
    }
    .dropdown-content ul{
        padding: 0;
        margin: -5px 0;
    }
    .dropdown-content ul > *{
        margin: 5px 0;
    }

    .dropdown-content li{
        padding: 0;
        display: flex;
        align-items: center;
        padding-left: 20px;
        position: relative;
    }

    .dropdown-content li::before{
        content: "";
        height: 8px;
        width: 8px;
        background-color: var(--black);
        position: absolute;
        top: 4px;
        left: 15px;
        border-radius: 50%;
    }

    .dropdown-content a:hover {
        color: var(--blue);
        transition: 0.3s ease;
    }
}
    
@media (min-width: 991.99px){
    .dropdown-option{
        position: relative;
        display: inline-block;
        height: 100%;
    }
    
    .dropbtn{
        height: 100%;
        display: flex;
        align-items: center;
    }

    .dropbtn > *{
        margin-left: 5px;
    }
    
    .dropdown-content {
        display: none;
        position: absolute;
        top: 75.98px;
        left: -15px;
        background-color: rgba(255, 255, 255, 0.85);
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        -moz-backdrop-filter: blur(4px);
        -ms-backdrop-filter: blur(4px);
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }
    
    .dropdown-content a, .dropdown-content .option{
        color: var(--black);
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        transition: 0.3s ease;
    }
      
    .dropdown-content a:hover, .dropdown-content .option:hover{
        background-color: var(--white);
        color: var(--blue);
        transition: 0.3s ease;
    }
      
    .dropdown-option:hover .dropdown-content {
        display: block;
    }
     
    .dropdown-content ul{
        flex-direction: column;
    }

    .dropdown-content li{
        width: 100%;
    }
}


