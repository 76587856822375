.homeMain{ 
    height: 100vh;
    position: relative;
    background-color: var(--black);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767.98px) {
  .homeMain:before{ 
    background: url("../../../../../public/images/home/main-background-6.jpg") center no-repeat; 
    background-position: center center;
    background-size: cover;
    height: auto;
    background-attachment: scroll;
    content: "";
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;  
  }
}
  
@media (min-width: 767.99px) and (max-width: 991.98px){
  .homeMain:before{ 
    background: url("../../../../../public/images/home/main-background-6.jpg") center no-repeat; 
    background-position: center center;
    background-size: cover;
    height: auto;
    background-attachment: scroll;
    content: "";
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;  
  }
}

@media (min-width: 991.99px) and (max-width: 1199.98px) {
  .homeMain:before{ 
    background: url("../../../../../public/images/home/main-background-6.jpg") center no-repeat; 
    background-position: center center;
    background-size: cover;
    height: auto;
    background-attachment: scroll;
    content: "";
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;  
  }
}

@media (min-width: 1199.99px){
  .homeMain:before{ 
    background: url("../../../../../public/images/home/main-background-6.jpg") no-repeat center center fixed; 
    background-position: center center;
    content: "";
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;  
  }
}


.mainText{
  margin-top: 50px;
  color: var(--white);
  opacity: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: right;
}

.mainText header{
  width: 70%;
}

@media (max-width: 599.98px) {
  .mainText .paragraph{
    width: 85%;
    margin-top: 0px;
  }
}

@media (min-width: 599.99px) and (max-width: 767.98px) {
  .mainText .paragraph{
    width: 65%;
    margin-top: 20px;
  }
}
  
@media (min-width: 767.99px){
  .mainText .paragraph{
    width: 55%;
    margin-top: 20px;
  }
}