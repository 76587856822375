.footerItem{
    display: flex;
    flex-direction: column;
    width: 250px;
}

.footerItem .icon{
    margin-bottom: 20px;
    font-size: 30px;
    color: var(--lightgrey);
}

.footerItem ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 15px;
    color: var(--lightgrey);
}

.footerItem li{
    display: flex;
    letter-spacing: 2px;
    margin: 0 -5px;
}

.footerItem li > *{
    margin: 0 5px;
}

.footerItem a{
    color: var(--lightgrey);
}