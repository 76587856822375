.logoNavbar{
    width: 130px;
    position: absolute;
    top: 19px;
}

.logoNavbar-mobile{
    width: 85px;
    position: absolute;
    top: 9px;
}

.logoNavbar img{
    width: 100%;
    position: absolute;
    z-index: 10000;
}

.logoNavbar-mobile img{
    width: 100%;
}