@media (max-width: 299.98px){
    .machines-carousel .swiper {
        width: 85vw;
    }
}

@media (min-width: 299.99px) and (max-width: 991.98px) {
    .machines-carousel .swiper {
        width: 75vw;
        height: 50vw;
    }
}
    
@media (min-width: 991.99px) and (max-width: 1199.98px) {
    .machines-carousel .swiper {
        width: 37.5vw;
        height: 25vw;
    }
}

@media (min-width: 1199.98px) {
    .machines-carousel .swiper {
        width: 600px;
        height: 400px;
    }  
}


.machines-carousel img{
    width: 100%;
    height: 100%;
}

.machines-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.machines-carousel::after{
    position: relative;
}

.machines-carousel::after{
    content: '';
    position: absolute;
    width: 100%;
    height: calc(100% - 90px);
    background-color: var(--red);
    border: 5px solid var(--red);
    top: -5px;
    left: -5px;
}
  
.machines-carousel .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.machines-carousel .swiper {
    padding-bottom: 30px;
    position: relative;
}  

.machines-carousel .swiper-pagination {
    position: absolute;
    bottom: 0px !important;
}

.machines-carousel .swiper-button-next, .machines-carousel .swiper-button-prev{
    color: var(--lightgrey);
    transition: 0.3s ease;
}
.machines-carousel .swiper-button-next:hover, .machines-carousel .swiper-button-prev:hover{
    color: var(--white);
    transition: 0.3s ease;
}

.machines-carousel .swiper-pagination-bullet-active{
    background: var(--red);
}

.machines-text{
    text-align: center;
    font-size: 25px;
    letter-spacing: 0.2vw;
    color: var(--red);
    font-weight: 600;
    padding-top: 25px;
    border-bottom: 1px solid var(--lightgrey);
}