@media(max-width: 369.98px){ 
  .navbar-content-icons{
    margin: 0;
  }
}

@media(min-width: 369.98px){ 
  .navbar-content-icons{
    margin: auto;
  }
}


@media(max-width: 369.98px){ 
  .navbar-content-icons {
    padding: 0% 5%;
  }
  .navbar-content{
    max-width: 300px;
    padding: 0% 5%;
  }
}

@media (min-width: 369.99px) and (max-width: 767.98px) {
  .navbar-content, .navbar-content-icons {
      max-width: 540px;
      padding: 0% 5%;
  }
}
  
@media (min-width: 767.99px) and (max-width: 991.98px) {
  .navbar-content, .navbar-content-icons {
    max-width: 720px;
    padding: 0% 5%;
  }
}
  
@media (min-width: 991.99px) and (max-width: 1199.98px) {
  .navbar-content, .navbar-content-icons {
    max-width: 960px;
  }
}
  
@media (min-width: 1199.99px) {
  .navbar-content, .navbar-content-icons {
    max-width: 1140px;
  }
}

.navbar-content-mobile{
  width: 100%;
  height: 100%;
}

.navbar-container{
  background-color: var(--white);
  display: flex;
  height: 75.98px;
  align-items: center;
  position: fixed;
  width: 100vw;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9999;
}

.navbar{
  background-color: var(--white);
  display: flex;
  height: 75.98px;
  align-items: center;
  position: fixed;
  width: 100vw;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 9999;
}

.navbar-content-icons{
    display: flex;
    justify-content: center;
    height: 100%;
}

.navbar-content{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: var(--white);
    position: relative;
}

.bars{
  color: var(--red);
  font-size: 30px;
  position: relative;
  margin-left: auto;
  cursor: pointer;
  transform: rotate(0deg);
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  min-width: 26px;
}

.bars[isvisible=true]{
  font-size: 40px;
  transform: rotate(-180deg);
  transition: transform 0.3s ease;
}

.scroll{
  box-shadow:  0.1rem 0.08rem rgb(0 0 0 / 8%)
}

.navbar .background-container .shadow{
  -webkit-filter: drop-shadow(0rem 0.1rem rgb(0 0 0 / 8%));
  filter: drop-shadow(0rem 0.1rem rgb(0 0 0 / 8%));
}

.navbar .background-container{
  position: absolute;
  left: 53.5px;
  top: 19px;
  z-index: -1;
}

.navbar .background{
  width: 17px;
  height: 93px;
  background-color: var(--white);
}

.navbar .background::after{
  content: '';
  position: absolute;
  right: -9.5px;
  top: 20.5px;
  width: 60px; 
  height: 60px;  
  transform: rotate(41deg);
  background-color: var(--white);
}

.navbar .background::before{
  content: '';
  position: absolute;
  left: -9.6px;
  top: 20.5px;
  width: 60px; 
  height: 60px;  
  transform: rotate(-41deg);
  background-color: var(--white);
}